.navigation-wrapper {
  position: relative;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: rgba(235, 238, 231, 0.6);
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  right: 5px;
}