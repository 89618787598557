@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  height: 100%;
  overflow-x: hidden;
}

.mesogheo-grid {
  @apply grid grid-cols-12 mx-0.5 md:mx-7 2lg:mx-5e sm2:mx-10 sm3:mx-16 gap-x-1 md:gap-x-1 lg:gap-x-3 sm2:gap-x-4 sm3:gap-x-8;
}

h3 {
  @apply font-normal text-22_green;
}

p {
  @apply font-light text-02_green;
}